import React from "react";
import PropTypes from 'prop-types'
import { graphql, StaticQuery } from 'gatsby'
import PreviewCompatibleImage from './PreviewCompatibleImage'
import VideoElement from './VideoElement'
import $ from 'jquery';

import '../sass/workListing.scss'

class WorkListing extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      videoID: '',
      videoMuted: true,
      videoPaused: true,
    }
  }

  componentDidMount() {
    var that = this;
    var caseStudy = document.querySelectorAll('.case-study');
    $(window).on('scroll', function() {
      that.getMostVisible(caseStudy);
    })
  }

  //video muted or not so much
  handleVideoClick = () => {
    if ( this.state.videoMuted === true) {
      this.setState({ videoMuted: false });
    } else {
      this.setState({ videoMuted: true });
    }
    // console.log('muted: ' + this.state.videoMuted);
  }

  getMostVisible = ($elements) => {
    var that = this;
    var element,
        viewportHeight = $(window).height(),
        max = 0;

    $elements.forEach(function($element, index) {
        var visiblePx = that.getVisibleHeightPx($($element), viewportHeight);

        if (visiblePx > max) {
            max = visiblePx;
            element = index;
        }
    });
    //console.log(element);
    that.setState({ videoID: element });
    //return $elements.filter(element);
  }

  getVisibleHeightPx = ($element, viewportHeight) => {
    //console.log($element);
    var rect = $element.get(0).getBoundingClientRect(),
        height = rect.bottom - rect.top,
        visible = {
            top: rect.top >= 0 && rect.top < viewportHeight,
            bottom: rect.bottom > 0 && rect.bottom < viewportHeight
        },
        visiblePx = 0;

    if (visible.top && visible.bottom) {
        // Whole element is visible
        visiblePx = height;
    } else if (visible.top) {
        visiblePx = viewportHeight - rect.top;
    } else if (visible.bottom) {
        visiblePx = rect.bottom;
    } else if (height > viewportHeight && rect.top < 0) {
        var absTop = Math.abs(rect.top);

        if (absTop < height) {
            // Part of the element is visible
            visiblePx = height - absTop;
        }
    }

    return visiblePx;
  }

  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark

    return (
      <div className="work-listing">
        { posts &&
          posts.map(({ node: post }, index) => (
            <div className="case-study" key={post.id}>
                <div className="case-wrap">
                  <div className="text-wrap">
                    <h4>Client</h4>
                    <h3>{post.frontmatter.clientName}</h3>
                  </div>
                  <div className="count">{ (Number(index) + 1) > 10 ? (Number(index) + 1) : '0' + (Number(index) + 1) }
                  </div>
                  {post.frontmatter.featuredimage && post.frontmatter.videoID ? (
                    <div className="featured-thumbnail"
                    >
                      <PreviewCompatibleImage
                        imageInfo={{
                          image: post.frontmatter.featuredimage,
                          alt: `${post.frontmatter.description}`,
                        }}
                      />
                    </div>
                  ) : null}

                  { this.state.videoID === index ? (
                    <div
                    className="case-video"
                    >
                      <div className={this.state.videoMuted ? "hitArea" : "hitArea unmute"}
                        role="button"
                        onClick={()=> this.handleVideoClick()}
                        onKeyPress={()=> this.handleVideoClick()}
                        tabIndex={index}
                        aria-label={this.state.videoMuted ? "Unmute Volume" : "Mute Volume"}
                      />
                      <div className="lds-roller-wrap">
                        <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                      </div>
                      <VideoElement
                        videoInfo={{
                          src: post.frontmatter.videoID,
                          mute: this.state.videoMuted,
                          playing: this.state.videoPaused,
                        }}
                      />
                    </div>
                  ): null }

                </div>
            </div>
          ))}
      </div>
    )
  }
}

WorkListing.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query WorkListingQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "video-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                videoID
                clientName
                description
                templateKey
                date(formatString: "MMMM DD, YYYY")
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 575, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <WorkListing data={data} count={count} />}
  />
)
