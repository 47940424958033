import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
//import Features from '../components/Features'
import VideoReel from '../components/VideoReel'
import WorkListing from '../components/WorkListing'

import '../sass/home.scss'
import '../sass/intro.scss'

export const IndexPageTemplate = ({
  video,
  intro,
  introDescription,
  contact,
  title,
  contactDescription,
  name,
  jobTitle,
  email,
}) => (
  <div className="home">
    <div
      id="reel"
    >
      <div
      className="reel-video"
      >
        <VideoReel
          videoInfo={{
            src: video,
            mute: 'true',
          }}
        />
      </div>

      <a href="#work">View Our Work</a>
    </div>
    <div className="section intro">
      <div className="content">
        <div className="headline">
          <h1>{intro.introDescription}</h1>
        </div>
      </div>
    </div>
    <div className="section work">
      <div
      id="work"
      className="column is-12"
      >
        <WorkListing />
      </div>
    </div>
    <div id="contact" className="Contact">
        <div className="sidebar"><span>Contact</span></div>
        <div className="content">
          <h2>{contact.title}</h2>
          <h4>{contact.contactDescription}</h4>
          <p>{contact.name}</p>
          <p>{contact.jobTitle}</p>
          <a href={"mailto:" + contact.email }>{contact.email}</a>
        </div>
    </div>
  </div>
)

IndexPageTemplate.propTypes = {
  video: PropTypes.string,
  intro: PropTypes.object,
  introDescription: PropTypes.string,
  // intro: PropTypes.shape({
  //   blurbs: PropTypes.array,
  // }),
  contact: PropTypes.object,
  title: PropTypes.string,
  contactDescription: PropTypes.string,
  name: PropTypes.string,
  jobTitle: PropTypes.string,
  email: PropTypes.string,
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <IndexPageTemplate
        video={frontmatter.video}
        intro={frontmatter.intro}
        introDescription={frontmatter.introDescription}
        contact={frontmatter.contact}
        title={frontmatter.title}
        contactDescription={frontmatter.contactDescription}
        name={frontmatter.name}
        jobTitle={frontmatter.jobTitle}
        email={frontmatter.email}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        video
        intro {
          introDescription
        }
        contact {
          title
          contactDescription
          name
          jobTitle
          email
        }
      }
    }
  }
`
