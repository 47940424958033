import React from 'react'
import VideoElement from './VideoElement'
import '../sass/video.scss'

class VideoReel extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      videoMuted: true,
      videoPaused: true,
    }
  }

  componentDidMount() {
    var that = this;
    var videoReel = document.querySelector('.reel-video');
    that.objectListener(videoReel);
  }

  objectListener = (item) => {
    var that = this;
    window.addEventListener('scroll', function (event) {
      if (that.isInViewport( item )) {
        that.setState({ videoPaused: true });
      } else {
        that.setState({ videoPaused: false });
      }
    }, false);
  }

  handleVideoClick = () => {
    if ( this.state.videoMuted === true) {
      this.setState({ videoMuted: false });
    } else {
      this.setState({ videoMuted: true });
    }
  }

  isInViewport = (el) => {
    var rect = el.getBoundingClientRect();
    var elemTop = rect.top;
    var elemBottom = rect.bottom;

    // Only completely visible elements return true:
    //var isVisible = (elemTop >= 0) && (elemBottom <= window.innerHeight);
    // Partially visible elements return true:
    var isVisible = elemTop < window.innerHeight && elemBottom >= 0;
    return isVisible;
  }

  render() {
    return (
        <div
        className="video-reel"
        >
          <div className={this.state.videoMuted ? "hitArea" : "hitArea unmute"}
            role="button"
            onClick={()=> this.handleVideoClick()}
            onKeyPress={()=> this.handleVideoClick()}
            tabIndex="0"
            aria-label={"Unmute Volume"}
          />
          <VideoElement
            videoInfo={{
              src: this.props.videoInfo.src,
              mute: this.state.videoMuted,
              playing: this.state.videoPaused,
            }}
          />
        </div>
    )
  }
}

export default VideoReel
