import React from 'react'
import ReactPlayer from "react-player"
import '../sass/video.scss'

const VideoElement = ({ videoInfo }) => {
  const src = videoInfo.src;
  var videoMuted = videoInfo.mute;
  var videoPlaying = videoInfo.playing;

  const fadeIn = (event) => {
    var videoWrap = document.querySelector('.case-study .videoWrapper');
    var loader = document.querySelector('.case-study .lds-roller-wrap');
    loader.classList.remove("active");
    videoWrap.classList.add("fadeIn");
  };

  const buffer = (event) => {
    var loader = document.querySelector('.case-study .lds-roller-wrap');
    loader.classList.add("active");
  };

  //console.log("muted: " + videoMuted);

  if (videoInfo.src) {
    return (
        <div className="videoWrapper">
        {
        //  <span data-vimeo="unmute">Toggle Mute</span>
        //  <iframe src={`https://player.vimeo.com/video/` + src + `?autoplay=1&loop=1&autopause=0&muted=1`} width="600" height="400" crossOrigin="anonymous" title="video" muted />
        }
            <ReactPlayer
              url={'https://vimeo.com/' + src }
              volume={1}
              muted={videoMuted}
              loop={true}
              playing={videoPlaying}
              width={0}
              height={0}
              onPlay={fadeIn}
              onBuffer={buffer}
            />
      </div>
    )
  }
  return null
}

export default VideoElement
